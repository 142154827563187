.Footer {
    background: #f2f2f2;
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
    text-align: center;
}

.Footer-content {
    height: 100%;
    width: 75%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.Footer-div {
    margin: auto auto;
    display: flex;
}

.Footer-font {
    font-size: 1.1rem !important;
    padding-bottom: 1rem;
}

.Footer-font2 {
    font-size: 1.1rem !important;
    padding-top: 1rem;
}

.Footer-icons-text {
    display: flex;
    margin: auto;
}

.Footer-social-media {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width: 767px) {
    .Footer {
        height: 100%;
    }

    .Footer-content {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: none;
    }

}