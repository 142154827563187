.VideoHome {
    margin: 2rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #F9F9F9;
}

.VideoHome-video {
    width: 100%;
    margin: auto;
}

.VideoHome-content {
    width: 85%;
    margin: 2rem auto;
    text-align: left;
}

.VideoHome-content-bottom {
    margin-top: 3rem;
    display: grid;
    grid-template-rows: 4fr 1fr;
}

@media only screen and (max-width: 850px) {
    .VideoHome {
        grid-template-rows: 2fr 1fr;
        grid-template-columns: none;
    }
}