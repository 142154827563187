.Us {
    margin-top: 20vmin;
    width: 100%;
}

.Us-title {
    margin-bottom: 3rem;
}

.Us-title img {
    width: 100%;
}

.Us-message {
    width: 50%;
    margin: 3rem auto;
}

.Us-video {
    width: 50%;
    margin: auto;
    height: 56vmin;
}

.Us-companies {
    width: 100%;
    margin: 6rem auto 0 auto;
    /* text-align: center; */
}

.MuiTabs-flexContainer {
    height: 100%;
}

.MuiTab-root {
    font-size: 2.5vmin !important;
    font-family: unset !important;
}

.UsTab {
    height: 80vmin;
}

@media only screen and (min-width: 768px) and (max-width: 1010px)  {
    .Us {
        margin-top: 15%;
    }

    .Us-message {
        width: 80%;
    }
}

@media only screen and (max-width: 767px) {
    .Us-message {
        width: 80%;
    }

    .Us-video {
        width: 80%;
    }

    .MuiTab-wrapper {
        font-size: .8rem;
    }

    .UsTab {
        height: 180vmin;
    }

    .Us-message {
        width: 90%;
    }
    
}