.Header-grid {
    display: grid;
    grid-template-columns: 1.2fr .8fr 3fr;
}

.Header-menu-button {
    display: block;
    margin-left: auto;
    margin-right: 2rem;
    margin-top: 1rem;
}

.Header-menu-item {
    font-size: 0.9rem !important;
    min-height: auto !important;
}

.Header-menu-list {
    margin-left: -5rem !important;
}

.Header-grid-menu {
    width: 100%;
    margin: auto auto auto 15rem;
    height: 100%;
    display: flex;
}

.Header-fixed {
    text-align: center;
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
    animation: smoothScroll 1s forwards;
    box-shadow: 0px 3px 7px -2px rgb(0 0 0 / 22%);
    margin-bottom: 10px;
    z-index: 5;
}