.Contact {
    width: 70%;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.Contact-section-1 {
    margin: 2rem;
}

.Contact-section-2 {
    padding: 2rem;
    border-left: 1px solid #cccccc;
}

.Contact .Contact-section-1 div .Footer-icons-text {
    margin: 2rem auto;
}

.Form-address {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.Contact-textField {
    color: gray !important;
}

.Mui-focused {
    color: gray !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: grey !important;
    border-width: 2px;
}

@media only screen and (max-width: 767px) {
    .Contact {
        width: 90%;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: none;
    }

    .Contact-section-2 {
        border-top: 1px solid #cccccc;
        margin-top: -3rem;
        border-left: none;
    }
}