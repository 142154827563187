.MediaCard {
    width: 90%;
    margin: 1rem auto;
}

.MediaCard-img {
    width: 100% !important;
    margin: auto;
}

.GridCompanies {
    display: flex;
    justify-content: center;
}

.card-content {
    height: 12vw;
}

@media only screen and (max-width: 850px) {
    .card-content {
        height: 100%;
    }
}