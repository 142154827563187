.InfoGrid {
    margin-top: -10rem;
    background: #F9F9F9;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.InfoGrid-img {
    width: 100%;
    margin: 2rem auto 0rem auto;
}

.InfoGrid-info {
    width: 70%;
    margin: 2rem auto 0rem auto;
    /* background-color: #FE730B;
    background-image: linear-gradient(to right, #fcc397, #fcf9f4) */
}

.InfoGrid-div-button {
    width: 100%;
    margin-top: 3rem;
}

.InfoGrid-button {
    color: white !important;
    background-color: #1C8E2C !important;
}

.InfoGrid-social {
    width: 50%;
    margin: 3rem auto;
    display: flex;
}

.InfoGrid-social .Footer-div div h6 {
    font-size: 0.98rem !important;
}

.we {
    color: #26645A;
}

.we2 {
    color: #FE730B;
}

@media only screen and (max-width: 850px) {
    .InfoGrid {
        margin-top: 0;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: none;
    }

    .InfoGrid-img {
        display: none;
    }

    .InfoGrid-social {
        width: 90%;
        margin: 2rem auto;
    }
}